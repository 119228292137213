import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {BlackButton} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function WhitefieldBangalore(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "MyMovers in Whitefield, Bangalore,Karnataka";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setLoading(false);
      setCity(Object.keys(data)[0]);
    })

  }, []);

  if(loading) {
    return <Loading />
  }

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    }  else if(city === "Jagatpura") { 
      props.history.push(`/packers-and-movers-in-jagatpura-jaipur`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-bangalore`);
      return;
    } else if (city === "Whitefield") {
      props.history.push(`/packers-and-movers-in-whitefield-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers and Movers in Whitefield, Bangalore, Karnataka</h1>
        <h3><a href="https://mymovers.in">MyMovers.in</a> | Calculate movers and packers charges in Whitefield, Bangalore</h3>

        <p>
        If you are searching for the packers and movers near me in Whitefield, My Movers Packers and Movers could emerge out as your most preferred partner. 
        Backed by over a decade of expertise in serving both residential and commercial packers and movers in Whitefield, 
        we take pride in offering best-in-class moving services in Whitefield.</p>
        
        <h4>Loading and Unloading Services</h4>
        <p>Our foremost objective is to let our clients enjoy complete peace of mind while we take care of their 
          moving-related needs in the most remarkable and reliable manner. Our staff are pioneers in this field 
          and are committed to offer you top notch professional services since our motto is “Customer Is King”. 
          Well maintained fleet and helpers armed with the latest moving equipment are always set to serve you</p>

        <h4>Movers and Packers Whitefield</h4>
        <p>
        Are you looking best movers and packers in Whitefield then you at right track We, 
        My Movers Packers and movers in Whitefield is the best Movers and Packers company in Whitefield who offers safety 
        and Hassle free relocation (office, home, Car, Bike) services in Whitefield. We are expert in movers packers, 
        packing-unpacking, household shifting, loading-unloading, transportation, car transportation, warehousing and 
        insurance for residential relocation, commercial relocation and international moving. We always strive for 
        providing new and improved solutions to the clients. For a stress-free, convenient and safe relocation service in 
        all over Whitefield, it is always good in relying upon most experienced My Movers Packers and Movers Whitefield 
        Having good years of experience in corporate and household relocation in Whitefield, we understand the importance 
        of careful planning and execution; this has made us a competent mover and packer in Whitefield. We feel proud for 
        serving diverse clients with great excellence and dedication that made us highly renowned and most trusted name in 
        the moving industry.
        </p>

        <h4>Trusted Packers and Movers in Whitefield</h4>
        <p>Nowadays there are so many packers & movers are available in the market, but most of them are a fraud & 
          they can not be trusted. To move your household goods, car or office shifting, you should choose one of the best 
          and Popular packers & movers Whitefield with quality service & experience. My Movers Packers and Movers is one 
          of the biggest moving companies in Whitefield having head offices in all metro cities. It is a Whitefield based 
          company whereas it is providing services to all the parts of India with quality service & on-time delivery when 
          you goods moving.</p>

        <p>My Movers packers and movers in Whitefield provides the best services all over the Whitefield.
        we have experience of more than 17 years in the relocation industry & is well known for its 
        quality service at affordable prices in Whitefield. My Movers Packers and Movers Whitefield tie-ups 
        with reputed & reliable moving companies.</p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>

        <br/>
        <h3>Whitefield, Bangalore Office address</h3>
        <p style={{marginBottom: '3rem'}}>  
          <b>MyMovers Packers and Movers</b><br/>
          156, Nandishwara Nilaya, 3rd Cross Rd, Hagadur, 
          Immadihalli, Whitefield, Vinayakanagara (Danadamande),
          Bengaluru, Karnataka 560066 <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9116550911">+91-9116550911</a> <br />
          Email ID: <a href="mailto:info@mymovers.in">info@mymovers.in</a> <br />
          Website: <a href="https://mymovers.in">MyMovers.in</a>
        </p>

        <CityContainer>
          {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default WhitefieldBangalore;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;